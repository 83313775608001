import type { ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import type {
	Notification,
	NotificationContextInterface,
	ZipDealNotification,
} from "~contexts/ZipDealNotificationTypes.d.ts";

export const NotificationContext = createContext<
	undefined | NotificationContextInterface
>(undefined);

export const NotificationProvider = ({
	children,
}: { children?: ReactNode }) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [modalNotifications, setModalNotifications] = useState<Notification[]>(
		[],
	);

	const removeNotification = (id: string) => {
		setNotifications((notifications) =>
			notifications.filter((n) => {
				return n.id !== id;
			}),
		);
	};

	const removeModalNotification = (id: string) => {
		setModalNotifications((notifications) =>
			notifications.filter((n) => {
				return n.id !== id;
			}),
		);
	};

	const addModalNotification = (
		notification: Partial<ZipDealNotification>,
		header,
		onClick = () => {},
		buttonText: string | undefined = undefined,
		options: { useMarkdown?: boolean; initialDelay?: number } = {},
	) => {
		const id = uuidv4();
		setTimeout(() => {
			setModalNotifications((notifications) => [
				...notifications,
				{
					id,
					message: notification,
					header,
					type: "modal",
					onClick,
					buttonText,
					useMarkdown: options.useMarkdown,
				},
			]);
		}, options.initialDelay || 0);
	};

	const addNotification = (
		notification: Partial<ZipDealNotification>,
		timeout: number | undefined = 5000,
		options: { useMarkdown?: boolean; initialDelay?: number } = {},
	) => {
		const id = uuidv4();
		setTimeout(() => {
			setNotifications((notifications) => [
				...notifications,
				{
					id,
					message: notification,
					type: "success",
					useMarkdown: options.useMarkdown,
				},
			]);
		}, options.initialDelay || 0);

		if (timeout !== undefined) {
			setTimeout(() => {
				setNotifications((notifications) =>
					notifications.filter((n) => {
						return n.id !== id;
					}),
				);
			}, timeout);
		}
	};

	const addErrorNotification = (
		notification: Partial<ZipDealNotification>,
		timeout: number | undefined = 5000,
		options: { useMarkdown?: boolean; initialDelay?: number } = {},
	) => {
		const id = uuidv4();
		setTimeout(() => {
			setNotifications((notifications) => [
				...notifications,
				{
					id,
					message: notification,
					type: "error",
					useMarkdown: options.useMarkdown,
				},
			]);
		}, options.initialDelay || 0);

		if (timeout !== undefined) {
			setTimeout(() => {
				setNotifications((notifications) =>
					notifications.filter((n) => {
						return n.id !== id;
					}),
				);
			}, timeout);
		}
	};

	return (
		<NotificationContext.Provider
			value={{
				notifications,
				addNotification,
				removeNotification,
				addErrorNotification,
				removeModalNotification,
				addModalNotification,
				modalNotifications,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotifications = (): NotificationContextInterface => {
	const context = useContext(NotificationContext);
	if (context === undefined) {
		throw Error("You need to useNotifications in a NotificationProvider!");
	}

	return context;
};
